/** @format */

import { useEffect, useState } from "react";
import { formatTitle, GetData } from "./Integration/ApiIntegration";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router";

export const AdSideBanner = ({ img, link }) => {
  return img ? (
    link ? (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img src={img} alt="" className={`ad-side-banner`} />
      </a>
    ) : (
      <img src={img} alt="" className={`ad-side-banner`} />
    )
  ) : null;
};

export const TopBanner = ({ img, className, link }) => {
  return img ? (
    link ? (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img src={img} alt="" className={`top-banner ${className}`} />
      </a>
    ) : (
      <img src={img} alt="" className={`top-banner ${className}`} />
    )
  ) : null;
};

export const SpecialBox = () => {
  const [specialBanner, setSpecialBanner] = useState([]);
  const navigate = useNavigate();
  const getAllSpecialBanners = () => {
    GetData("userAuth/getPostByTitle/special-posts").then((res) => {
      setSpecialBanner(res?.data);
    });
  };

  useEffect(() => {
    getAllSpecialBanners();
  }, []);
  return (
    specialBanner?.length > 0 && (
      <div className="special-box-container">
        <p className="heading">SPECIALS</p>
        <div className="box-cont">
          {specialBanner?.map((item, index) => (
            <div onClick={() => navigate(`/special/${item?._id}/${formatTitle(item?.name)}`)} className="box" key={index} >
              <img src={item?.image} alt="" />
              <p className="title">{item?.subtitle}</p>
              <p className="description">{item?.description}</p>
            </div>
          ))}
        </div>
      </div >
    )
  );
};

export const TableLayout = ({ className, thead, tbody }) => {
  return (
    <Table className={className} responsive>
      <thead>
        <tr>
          {thead?.map((i, index) => (
            <th key={`thead${index}`}> {i} </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tbody?.map((rowData, rowIndex) => (
          <tr key={`row${rowIndex}`}>
            {rowData?.map((cellData, cellIndex) => (
              <td key={`cell${cellIndex}`}>{cellData}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
