/** @format */
import cric from "../Assets/Homepage/cric.svg";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import editorpick from "../Assets/Homepage/editorpick.svg";
import { useEffect, useState } from "react";
import {
  AuthToken,
  GetData,
  baseUrl,
  convertStringFormat,
  formatTitle,
} from "../Components/Integration/ApiIntegration";
import axios from "axios";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Helmet } from "react-helmet";
import {
  AdSideBanner,
  SpecialBox,
  TopBanner,
} from "../Components/HelpingComponent";
import CurrentSeries from "../Components/CurrentSeries";
import RankingSeries from "../Components/RankingSeries";
import Livescrore from "./Livescrore";

const Homepage = () => {
  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, right: "10px", zIndex: 1 }}
        onClick={onClick}
      >
        <span>&#8594;</span>
      </div>
    );
  };
  function dateUpdate(dateString) {
    const date = new Date(dateString);

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = daysOfWeek[date.getUTCDay()];
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];

    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    minutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedDate = `${dayOfWeek}, ${day} ${month}, ${hours}:${minutes} ${ampm}`;

    return formattedDate;
  }
  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ marginLeft: "2rem", ...style, zIndex: 1 }}
        onClick={onClick}
      >
        <span>&#8592;</span>
      </div>
    );
  };

  const editorsettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
  const navigate = useNavigate();
  const [feacturePosts, setFeacturePosts] = useState([]);
  const [topStories, setTopStories] = useState([]);
  const [editorpicks, setEditorpicks] = useState([]);
  const [hompageBanner2, setHompageBanner2] = useState("");
  const [hompageBanner3, setHompageBanner3] = useState("");
  const [hompageBanner4, setHompageBanner4] = useState("");
  const [hompageBanner5, setHompageBanner5] = useState("");
  const [hompageBanner6, setHompageBanner6] = useState("");
  const [hompageBanner7, setHompageBanner7] = useState("");
  const [hompageBanner8, setHompageBanner8] = useState("");
  const [hompageBanner2Link, setHompageBanner2Link] = useState("");
  const [hompageBanner3Link, setHompageBanner3Link] = useState("");
  const [hompageBanner4Link, setHompageBanner4Link] = useState("");
  const [hompageBanner5Link, setHompageBanner5Link] = useState("");
  const [hompageBanner6Link, setHompageBanner6Link] = useState("");
  const [hompageBanner7Link, setHompageBanner7Link] = useState("");
  const [hompageBanner8Link, setHompageBanner8Link] = useState("");

  const [sliderData, setSliderData] = useState([]);

  const getSliderDataMatch = async () => {
    const response = await axios.get(
      baseUrl + "user/getMatchesList?type=mixed&paged=1&per_page=10&type=mixed",
      {
        params: {
          token: AuthToken,
        },
      }
    );

    setSliderData(response?.data?.response?.matches);
  };

  useEffect(() => {
    getSliderDataMatch();
    const intervalId = setInterval(getSliderDataMatch, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const getAllHomePageBanners = async () => {
    try {
      const res2 = await axios.get(`${baseUrl}admin/getAllPosts`);
      setHompageBanner2(
        res2?.data?.data?.find((item) => item.title === "hompageBanner2")?.image
      );
      setHompageBanner2Link(
        res2?.data?.data?.find((item) => item.title === "hompageBanner2")?.link
      );

      setHompageBanner3(
        res2?.data?.data?.find((item) => item.title === "hompageBanner3")?.image
      );
      setHompageBanner3Link(
        res2?.data?.data?.find((item) => item.title === "hompageBanner3")?.link
      );
      setHompageBanner4(
        res2?.data?.data?.find((item) => item.title === "hompageBanner4")?.image
      );
      setHompageBanner4Link(
        res2?.data?.data?.find((item) => item.title === "hompageBanner4")?.link
      );

      setHompageBanner5(
        res2?.data?.data?.find((item) => item.title === "hompageBanner5")?.image
      );
      setHompageBanner5Link(
        res2?.data?.data?.find((item) => item.title === "hompageBanner5")?.link
      );
      setHompageBanner6(
        res2?.data?.data?.find((item) => item.title === "hompageBanner6")
      );
      setHompageBanner6Link(
        res2?.data?.data?.find((item) => item.title === "hompageBanner6")?.link
      );
      setHompageBanner7(
        res2?.data?.data?.find((item) => item.title === "hompageBanner7")
      );
      setHompageBanner7Link(
        res2?.data?.data?.find((item) => item.title === "hompageBanner7")?.link
      );
      setHompageBanner8(
        res2?.data?.data?.find((item) => item.title === "hompageBanner8")
      );
      setHompageBanner8Link(
        res2?.data?.data?.find((item) => item.title === "hompageBanner8")?.link
      );
    } catch (error) {
      console.error("Error fetching homepage banners:", error);
    }
  };

  useEffect(() => {
    getAllHomePageBanners();
  }, []);

  const getAllFeacturePosts = () => {
    GetData(`userAuth/getPostByTitle/FeacturePost`).then((res) => {
      setFeacturePosts(res?.data);
    });
  };

  const getAllTopPosts = () => {
    GetData("userAuth/getPostByTitle/TopStories").then((res) => {
      setTopStories(res?.data);
    });
  };

  const getAllEditorsPickData = () => {
    GetData("userAuth/getPostByTitle/EditorsPick").then((res) => {
      setEditorpicks(res?.data);
    });
  };

  const [updateMetaData, setUpdateMetaData] = useState({
    title: "",
    description: "",
    image: "",
  });

  const getMetaData = async () => {
    const response = await axios.get(`${baseUrl}admin/getSiteSettings`);
    console.log(response?.data?.data);
    setUpdateMetaData({
      title: response?.data?.data?.titleTag,
      description: response?.data?.data?.metaDescription,
      image: response?.data?.data?.meta_image,
    });
  };

  useEffect(() => {
    getMetaData();
    getAllFeacturePosts();
    getAllTopPosts();
    getAllEditorsPickData();
  }, []);

  function timeAgo(createdAt) {
    const createdTime = new Date(createdAt).getTime();
    const currentTime = Date.now();
    const timeDifference = currentTime - createdTime;

    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);

    if (hoursDifference > 0) {
      return `${hoursDifference} hour${hoursDifference > 1 ? "s" : ""} ago`;
    } else if (minutesDifference > 0) {
      return `${minutesDifference} minute${
        minutesDifference > 1 ? "s" : ""
      } ago`;
    } else {
      return "just now";
    }
  }
  const CustomNextArrow1 = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#0F19AF",
          color: "white",
          marginRight: "2rem",
        }}
        onClick={onClick}
      />
    );
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showArrows, setShowArrows] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setShowArrows(window.innerWidth >= 468);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,

    variableWidth: true,
    nextArrow: showArrows ? <CustomNextArrow1 /> : null,
    prevArrow:
      currentSlide === 0 ? null : !showArrows ? null : <CustomPrevArrow />,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
  };

  return (
    <div className="max-container">
      <Helmet>
        <title>{updateMetaData?.title}</title>
        <meta name="description" content={updateMetaData?.description} />
        <meta name="keywords" content={updateMetaData?.metaKeywords} />
        <link rel="icon" href="/cricinningIcon.png" />
      </Helmet>

      {sliderData?.length > 0 && (
        <div className="top-slider-card-container">
          <Slider {...settings}>
            {sliderData?.map((item, index) => (
              <div key={index} className="top_slider_card">
                <div
                  onClick={() => {
                    if (item?.match_id) {
                      const teamAShortName = item.teama?.short_name
                        ?.toLowerCase()
                        .split(" ")
                        .join("-");
                      const teamBShortName = item.teamb?.short_name
                        ?.toLowerCase()
                        .split(" ")
                        .join("-");
                      const matchSuffix = convertStringFormat(
                        item?.subtitle
                      )?.toLowerCase();
                      const competitionTitle = item?.competition?.title
                        ?.toLowerCase()
                        .split(" ")
                        .join("-");
                      const competitionSeason =
                        item?.competition?.season?.toLowerCase();

                      const url = `/live-cricket-scores/${item.match_id}/${teamAShortName}-vs-${teamBShortName}-${matchSuffix}-${competitionTitle}-${competitionSeason}`;

                      navigate(url);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                  className="top_slider_card_div1"
                >
                  <div className="top_slider_card_div1_text">
                    <p
                      style={{
                        width: "90%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <span>{item?.subtitle} </span>
                      <span>
                        <Icon
                          icon="radix-icons:dot-filled"
                          width="1.2rem"
                          height="1.2rem"
                          style={{ color: "gray" }}
                        />
                      </span>
                      {item?.competition?.title}
                    </p>
                    <p
                      style={{
                        backgroundColor:
                          item?.format_str?.split(" ")?.[0] === "Test"
                            ? "red"
                            : item?.format_str?.split(" ")?.[0] === "Women"
                            ? "#FF00A9"
                            : "gray",
                      }}
                    >
                      {item?.format_str?.slice(0, 5)}
                    </p>
                  </div>
                  <div
                    style={{ lineHeight: "0" }}
                    className="top_slider_card_div2"
                  >
                    <div className="top_slider_card_div2_text">
                      <p>
                        <img
                          className="top_slider_card_div2_img"
                          src={item?.teama?.logo_url}
                          alt="logo"
                        />
                      </p>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span className="text-sm">
                          {item?.teama?.short_name}
                        </span>
                        <span>{item?.format_str==="Test"?item?.teama?.scores: item?.teama?.scores_full}</span>
                      </p>
                    </div>
                    <div className="top_slider_card_div2_text">
                      <p>
                        <img
                          className="top_slider_card_div2_img"
                          src={item?.teamb?.logo_url}
                          alt=""
                        />
                      </p>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span className="text-sm">
                          {item?.teamb?.short_name}
                        </span>
                        <span>
                          
                        {item?.format_str==="Test"?item?.teamb?.scores: item?.teamb?.scores_full}

                        </span>
                      </p>
                    </div>
                    <span>
                      {item?.status === 1 && (
                        <span
                          style={{
                            fontSize: "12px",
                            color: "rgb(163, 101, 1)",
                          }}
                        >
                          {dateUpdate(item?.date_start)}
                        </span>
                      )}
                      {item?.status === 2 && (
                        <span
                          style={{
                            fontSize: "12px",
                            color: "rgb(24, 102, 219)",
                          }}
                        >
                          {item?.result}
                        </span>
                      )}
                      {item?.status === 3 && (
                        <span
                          style={{
                            fontSize: "12px",
                            color: "rgb(24, 102, 219)",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item?.live?.slice(0, 30) + "..."}
                        </span>
                      )}
                    </span>
                  </div>
                </div>
                <div className="top_slider_card_div2_text11">
                  <div className="top_slider_card_div2_text11_text23">
                    {item?.competition?.total_teams > 2 && (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            `/cricket-series/${
                              item?.competition?.cid
                            }/${formatTitle(item?.competition?.title)}-${
                              item?.competition?.season
                            }/points-table`
                          )
                        }
                      >
                        Points Table
                      </span>
                    )}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          `/cricket-series/${
                            item?.competition?.cid
                          }/${formatTitle(item?.competition?.title)}-${
                            item?.competition?.season
                          }/matches`
                        )
                      }
                    >
                      Schedule
                    </span>
                  </div>
                </div>
              </div>
            ))}

            {hompageBanner2 &&
              hompageBanner2Link &&
              sliderData?.length >= 1 && (
                <Link to={hompageBanner2Link}>
                  <div
                    style={{ border: "1px solid red", cursor: "pointer" }}
                    className="top_slider_card"
                  >
                    <div className="top_slider_card_div1">
                      <img
                        src={hompageBanner2}
                        style={{ width: "100%", height: "100%" }}
                        alt="banner"
                      />
                    </div>
                  </div>
                </Link>
              )}
          </Slider>
        </div>
      )}

      <TopBanner img={hompageBanner3} className="mt-[2rem]" />

      <div className="bg-[#EEEEEE] pb-5  ">
        <Livescrore />
        {false && (
          <div className="flex justify-center pt-2 gap-5 main-div">
            {feacturePosts?.length > 0 && (
              <div>
                <div className="flex justify-between m-2">
                  <div className="font-semibold small-text">FEATURE POSTS</div>
                  <div
                    className="text-[#0F19AF] font-semibold cursor-pointer small-text"
                    onClick={() => navigate("/cricket-news")}
                  >
                    SEE ALL
                  </div>
                </div>

                <div className="feacturePosts">
                  {feacturePosts?.map((item) => (
                    <div key={item?._id} className="feacturePosts_div">
                      <div
                        onClick={() =>
                          navigate(
                            `/feacture/${item?._id}/${formatTitle(item?.name)}`
                          )
                        }
                        className="w-[300px] flex gap-2 cursor-pointer full-width"
                      >
                        <div style={{ width: "130px" }}>
                          <img
                            style={{
                              height: "100px",
                              borderRadius: "10px",
                              maxWidth: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                            src={item?.image || cric}
                            alt=""
                          />
                        </div>

                        <div className="text-sm">
                          <div className="bold-font small-text">
                            {item?.subtitle}
                          </div>
                          <span className="text-[#929394] xs-small-text">
                            {timeAgo(item?.createdAt)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <TopBanner
                  img={hompageBanner4}
                  link={hompageBanner4Link}
                  className={"mt-2"}
                />
                <TopBanner
                  img={hompageBanner5}
                  link={hompageBanner5Link}
                  className={"mt-2"}
                />

                {topStories?.length > 0 && (
                  <div>
                    <div className="flex justify-between m-2">
                      <div className="font-semibold mt-2 medium-text">
                        TOP STORIES
                      </div>
                      <div
                        className="text-[#0F19AF] font-semibold cursor-pointer"
                        onClick={() => navigate("/feature-posts")}
                      ></div>
                    </div>
                    <div className="feacturePosts">
                      {topStories?.map((item) => (
                        <div
                          className="feacturePosts_div cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/top-stories/${item?._id}/${formatTitle(
                                item?.name
                              )}`
                            )
                          }
                        >
                          <div className="flex gap-2 full-width">
                            <div>
                              <img src={cric} alt="" />
                            </div>

                            <div className="text-sm">
                              <div className="bold-font small-text">
                                {item?.subtitle}
                              </div>

                              <span className="text-[#929394] xs-small-text">
                                {timeAgo(item?.createdAt)} {item?.description}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {hompageBanner6 && hompageBanner6Link && (
                  <a
                    href={hompageBanner6Link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="middleBannerBig">
                      <p>{hompageBanner6?.name}</p>
                      <div>
                        <div className="middleBannerImage">
                          <img src={hompageBanner6?.image} alt="middleBanner" />
                        </div>
                        <div className="middleBannerText">
                          <p>{hompageBanner6?.subtitle}</p>
                          <p>{hompageBanner6?.description}</p>
                        </div>
                      </div>
                    </div>
                  </a>
                )}

                {editorpicks?.length > 1 && (
                  <div className="small-padding">
                    <div className="text-sm mt-2 font-semibold medium-text ">
                      Editors Pick
                    </div>

                    <div className="w-[650px] h-[300px]  mt-2 pt-4 bg-white rounded-lg  shadow-lg full-width ">
                      <Slider {...editorsettings}>
                        {editorpicks?.map((item) => (
                          <div
                            className="editorPick cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/editor-pick/${item?._id}/${formatTitle(
                                  item?.subtitle
                                )}`
                              )
                            }
                          >
                            <img
                              style={{
                                width: "100%",
                                height: "200px",
                                borderRadius: "10px 10px 0 0",
                                overflow: "hidden",
                              }}
                              src={item?.image || editorpick}
                              alt=""
                            />
                            <div
                              style={{
                                width: "90%",
                                margin: "auto",
                                lineHeight: "normal",
                                fontSize: "12px",
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: "bold",
                                  color: "black",
                                  paddingTop: "0.2rem",
                                  lineHeight: "normal",
                                }}
                              >
                                {item?.subtitle}
                              </p>
                              <p style={{ color: "gray", fontSize: "10px" }}>
                                {item?.description}
                              </p>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="small-padding">
              <div className="w-[250px] mt-10 full-width zero-margin ">
                <CurrentSeries />
                <AdSideBanner
                  img={hompageBanner7?.image}
                  link={hompageBanner7Link}
                  className={"mt-2"}
                />
                <RankingSeries />
                <AdSideBanner
                  img={hompageBanner8?.image}
                  link={hompageBanner8Link}
                  className={"mt-2"}
                />
                <SpecialBox />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Homepage;
