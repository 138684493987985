import axios from "axios";
import { useEffect, useState } from "react";

const Sitemap = () => {
  const [sitemapData, setSitemapData] = useState({});
  useEffect(() => {
    const generateSitemap = async () => {
      try {
        const response = await axios.get(
          "https://cricinnings.in/api/sitemap.xml"
        );
        console.log(response.data);
        setSitemapData(response.data);
      } catch (error) {
        console.error("Error fetching the sitemap:", error);
      }
    };

    generateSitemap();
  }, []);

  return <div>{sitemapData}</div>;
};

export default Sitemap;
