/** @format */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import { GetData } from "../Components/Integration/ApiIntegration";

const AboutPage = () => {
  const [terms, setTerms] = useState([]);

  const getAllTermsData = () => {
    GetData("userAuth/getAll/About").then((res) => {
      setTerms(res?.data);
    });
  };

  useEffect(() => {
    getAllTermsData();
  }, []);

  return (
    <div className="px-4 py-8">
      <div className="w-full">
        {terms[terms?.length - 1]?.content && (
          <div
            className="text-editor-container"
            dangerouslySetInnerHTML={{
              __html: terms[terms?.length - 1]?.content,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default AboutPage;
