/** @format */

import Commentarynavbar from "../Components/Commentarynavbar";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, formatTitle } from "../Components/Integration/ApiIntegration";
import { TopBanner } from "../Components/HelpingComponent";
import { Accordion, Tab, Table } from "react-bootstrap";
const Scorecard = () => {
  const { matchId } = useParams();
  const [squadData, setSquadData] = useState();
  const [banner1, setBanner1] = useState();
  const [banner2, setBanner2] = useState();
  const [banner3, setBanner3] = useState();
  const [resMobileStatus, setResMobileStatus] = useState("");
  const navigate = useNavigate();

  function formatDate11(dateString) {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }

      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const dayOfWeek = daysOfWeek[date.getUTCDay()];
      const month = months[date.getUTCMonth()];
      const day = date.getUTCDate();
      const year = date.getUTCFullYear();

      const formattedDate = `${dayOfWeek}, ${month} ${day}, ${year}`;

      return formattedDate;
    } catch (error) {
      return "";
    }
  }

  function formatDateTime(dateString) {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }

      const day = date.getUTCDate();
      const month = date.toLocaleString("default", { month: "short" });
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();

      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

      const formattedDateTime = `${formattedHours}:${formattedMinutes} ${period} (${month} ${day})`;

      return formattedDateTime;
    } catch (error) {
      return "";
    }
  }

  const getAllBanner = async () => {
    axios.get(baseUrl + "admin/getAllPosts").then((res) => {
      const banner = res?.data?.data;
      setBanner1(banner?.find((item) => item?.title === "scorePageBanner1"));
      setBanner2(banner?.find((item) => item?.title === "scorePageBanner2"));
      setBanner3(banner?.find((item) => item?.title === "scorePageBanner3"));
    });
  };

  useEffect(() => {
    getAllBanner();
  }, []);

  const getSquadData = async () => {
    axios.get(baseUrl + "user/scorecard/" + matchId).then((res) => {
      setSquadData(res?.data?.scorecard);
    });
  };

  function formatExtraRuns(extraRuns) {
    if (!extraRuns) return "";

    const formattedValues = [
      `b ${extraRuns.byes || 0}`,
      `lb ${extraRuns.legbyes || 0}`,
      `w ${extraRuns.wides || 0}`,
      `nb ${extraRuns.noballs || 0}`,
      `p ${extraRuns.penalty || 0}`,
    ];

    return `${extraRuns.total} (${formattedValues.join(", ")})`;
  }

  useEffect(() => {
    getSquadData();
  }, []);

  useEffect(() => {
    if (squadData?.status === 3) {
      setResMobileStatus("0");
    } else if (squadData?.status === 2) {
      setResMobileStatus("1");
    } else {
      setResMobileStatus("");
    }
  }, [squadData?.status]);

  return (
    <div className="">
      <div className="bg-[white] pl-2 pt-2">
        <Commentarynavbar />
        <TopBanner img={banner1?.image} className="mt-2" />

        <div>
          <p
            style={{
              color: squadData?.status === 2 ? "#1995EB" : "#EC0B37",
              fontWeight: "bold",
              marginLeft: "1rem",
              marginTop: "1rem",
            }}
          >
            {squadData?.status === 2 || squadData?.status === 3 ? (
              <span>{squadData?.status_note}</span>
            ) : null}
          </p>
        </div>
      </div>

      <div className="bg-white pb-5">
        <div className="flex justify-center pt-2 gap-5 column-flex">
          <div className="full-width">
            <div className="full-width">
              {squadData?.status === 1 && (
                <div className="w-full sm:w-[680px]  mt-5 mb-5 pb-5  text-center full-width">
                  <p>No Data Found</p>
                </div>
              )}
              <div className="showClass score-card-mobile-res">
                <div>
                  <div>
                    <Accordion defaultActiveKey="0" flush>
                      {squadData?.innings?.map((item, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                          <Accordion.Header>
                            <span className="accordiaon-scorecard">
                              <span> {item?.name}</span>{" "}
                              <span style={{ flex: "1" }}>
                                {item?.scores?.split("/")?.join("-")}{" "}
                                {item?.scores_full &&
                                  "(" +
                                    item?.scores_full
                                      ?.split("(")?.[1]
                                      ?.split(" ")?.[0] +
                                    ") Ov"}
                              </span>
                            </span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Table className="scoreCard-res-content-table">
                              <thead>
                                <tr className="scoreCard-res-content">
                                  <th>Batter</th>
                                  <th>R</th>
                                  <th>B</th>
                                  <th>4s</th>
                                  <th>6s</th>
                                  <th>SR</th>
                                </tr>
                              </thead>
                              <tbody>
                                {item?.batsmen?.map((batsman, batsmanIndex) => (
                                  <tr key={batsmanIndex}>
                                    <td>
                                      <p
                                        className="scoreCard-res-content-name"
                                        onClick={() =>
                                          navigate(
                                            `/profiles/${
                                              batsman?.batsman_id
                                            }/${formatTitle(batsman?.name)}`
                                          )
                                        }
                                      >
                                        <span>{batsman?.name}</span>
                                        <span
                                          onClick={() =>
                                            navigate(
                                              `/profiles/${
                                                batsman?.batsman_id
                                              }/${formatTitle(batsman?.name)}`
                                            )
                                          }
                                        >
                                          {batsman.how_out}
                                        </span>
                                      </p>
                                    </td>
                                    <td>
                                      <p>{batsman?.runs}</p>
                                    </td>
                                    <td>
                                      <p>{batsman?.balls_faced}</p>
                                    </td>
                                    <td>
                                      <p>{batsman?.fours}</p>
                                    </td>
                                    <td>
                                      <p>{batsman?.sixes}</p>
                                    </td>
                                    <td>
                                      <p>{batsman?.strike_rate}</p>
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                  <td>
                                    <p className="font-bold">Extras</p>
                                  </td>
                                  <td colSpan={5}>
                                    <p>{formatExtraRuns(item?.extra_runs)}</p>
                                  </td>
                                </tr>
                                <tr className="scoreCard-res-content-total-score">
                                  <td>
                                    <p>Total</p>
                                  </td>
                                  <td colSpan={5}>
                                    <p>{item?.scores_full}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={6}>
                                    <p className="scoreCard-res-content-name-didnot">
                                      <span>Did not Bat</span>
                                      <span>
                                        {item?.did_not_bat?.map(
                                          (player, index) => (
                                            <span
                                              onClick={() =>
                                                navigate(
                                                  `/profiles/${
                                                    player?.player_id
                                                  }/${formatTitle(
                                                    player?.name
                                                  )}`
                                                )
                                              }
                                              className="hover:underline hover:cursor-pointer"
                                              key={index}
                                            >
                                              {player?.name}
                                              {index !==
                                                item?.did_not_bat.length - 1 &&
                                                ","}
                                            </span>
                                          )
                                        )}
                                      </span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>

                            <Table className="scoreCard-res-content-table">
                              <thead>
                                <tr className="scoreCard-res-content">
                                  <th>Bowler</th>
                                  <th>O</th>
                                  <th>M</th>
                                  <th>R</th>
                                  <th>W</th>
                                  <th>NB</th>
                                  <th>WD</th>
                                  <th>ECO</th>
                                </tr>
                              </thead>
                              <tbody>
                                {item?.bowlers?.map((bowler, bowlerIndex) => (
                                  <tr key={bowlerIndex}>
                                    <td>
                                      <p
                                        onClick={() =>
                                          navigate(
                                            `/profiles/${
                                              bowler?.bowler_id
                                            }/${formatTitle(bowler?.name)}`
                                          )
                                        }
                                        className="text-color-blueRK"
                                      >
                                        <span>{bowler?.name}</span>
                                      </p>
                                    </td>
                                    <td>
                                      <p>{bowler?.overs}</p>
                                    </td>
                                    <td>
                                      <p>{bowler?.maidens}</p>
                                    </td>
                                    <td>
                                      <p>{bowler?.runs_conceded}</p>
                                    </td>
                                    <td>
                                      <p>{bowler?.wickets}</p>
                                    </td>
                                    <td>
                                      <p>{bowler?.noballs}</p>
                                    </td>
                                    <td>
                                      <p>{bowler?.wides}</p>
                                    </td>
                                    <td>
                                      <p>{bowler?.econ}</p>
                                    </td>
                                   
                                  </tr>
                                ))}
                              </tbody>
                            </Table>

                            <Table className="scoreCard-res-content-table">
                              <thead>
                                <tr className="scoreCard-res-content">
                                  <th>Fall of Wickets</th>
                                  <th>Score</th>
                                  <th>Over</th>
                                </tr>
                              </thead>
                              <tbody>
                                {item?.fows?.map((fow, fowIndex) => (
                                  <tr key={fowIndex}>
                                    <td>
                                      <p
                                        className="text-color-blueRK"
                                        onClick={() =>
                                          navigate(
                                            `/profiles/${
                                              fow?.batsman_id
                                            }/${formatTitle(fow?.name)}`
                                          )
                                        }
                                      >
                                        {fow?.name}
                                      </p>
                                    </td>
                                    <td>
                                      <p>{fow?.score_at_dismissal}</p>
                                    </td>
                                    <td>
                                      <p>{fow?.overs_at_dismissal}</p>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>

                            <Table className="scoreCard-res-content-table">
                              <thead>
                                <tr className="scoreCard-res-content">
                                  <th>Powerplays</th>
                                  <th>Over</th>
                                  <th>Runs</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td>
                                    <p>
                                      {item?.powerplay?.p1?.startover} -{" "}
                                      {item?.powerplay?.p1?.endover}
                                    </p>
                                  </td>
                                  <td>
                                    <p>{item?.result}</p>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                    <Table className="scoreCard-res-content-table">
                      <thead>
                        <tr className="scoreCard-res-content">
                          <th>INFO</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <p className="text-color-blueRK1"> Match</p>
                          </td>
                          <td>
                            <p className="text-color-blueRK2">
                              {squadData?.short_title}
                              {","} {squadData?.subtitle}
                              {","} {squadData?.competition?.title}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="text-color-blueRK1"> Series</p>
                          </td>
                          <td>
                            <p className="text-color-blueRK2">
                              {squadData?.competition?.title}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="text-color-blueRK1"> Date</p>
                          </td>
                          <td>
                            <p className="text-color-blueRK2">
                              {formatDate11(squadData?.date_start)}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="text-color-blueRK1"> Time</p>
                          </td>
                          <td>
                            <p className="text-color-blueRK2">
                              {formatDateTime(squadData?.date_start)}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="text-color-blueRK1"> Toss</p>
                          </td>
                          <td>
                            <p className="text-color-blueRK2">
                              {squadData?.toss?.text}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="text-color-blueRK1"> Venue</p>
                          </td>
                          <td>
                            <p className="text-color-blueRK2">
                              {squadData?.venue?.name}
                              {","} {squadData?.venue?.location}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="text-color-blueRK1"> Umpires</p>
                          </td>
                          <td>
                            <p className="text-color-blueRK2">
                              {squadData?.umpires?.split(",")[0]}
                              {","}
                              {squadData?.umpires?.split(",")[1]}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="text-color-blueRK1"> 3rd Umpire</p>
                          </td>
                          <td>
                            <p className="text-color-blueRK2">
                              {squadData?.umpires?.split(",")[2]}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="text-color-blueRK1"> Referee</p>
                          </td>
                          <td>
                            <p className="text-color-blueRK2">
                              {squadData?.referee}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className="hideClass w-full sm:w-[680px]">
                {squadData?.innings?.map((item, index) => (
                  <div key={index}>
                    <div className="w-full sm:w-[680px] mt-2 mb-2 bg-white rounded-lg shadow-lg">
                      <div className="bg-[#0F19AF] flex justify-between items-center rounded-t-lg w-full h-[45px] text-white">
                        <div className="ml-2">{item?.name}</div>
                        <div className="mr-2">
                          {item?.scores?.split("/").join("-")}{" "}
                          {item?.scores_full &&
                            `(${
                              item?.scores_full?.split("(")[1]?.split(" ")[0]
                            }) Ov`}
                        </div>
                      </div>

                      <table className="m-2">
                        <thead>
                          <tr className="border-b">
                            <th className="w-[150px] text-left">Batter</th>
                            <th className="w-[300px]"></th>
                            <th className="w-[50px] text-left">R</th>
                            <th className="w-[50px] text-left">B</th>
                            <th className="w-[50px] text-left">4S</th>
                            <th className="w-[50px] text-left">6S</th>
                            <th className="w-[50px]">SR</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.batsmen?.map((batsman, batsmanIndex) => (
                            <tr key={batsmanIndex} className="border-b">
                              <td
                                className="text-[#0F19AF] hover:underline hover:cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    `/profiles/${
                                      batsman?.batsman_id
                                    }/${formatTitle(batsman?.name)}`
                                  )
                                }
                              >
                                {batsman.name} {batsman?.role_str}
                              </td>
                              <td>{batsman.how_out}</td>
                              <td>{batsman.runs}</td>
                              <td>{batsman.balls_faced}</td>
                              <td>{batsman.fours}</td>
                              <td>{batsman.sixes}</td>
                              <td className="flex items-center">
                                {batsman.strike_rate}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div className="border-b ml-2 mr-2">
                        <div className="flex justify-between w-full sm:w-[550px]">
                          <div className="text-slate-400">EXTRAS</div>
                          <div className="text-slate-400 flex">
                            {formatExtraRuns(item?.extra_runs)}
                          </div>
                        </div>
                      </div>

                      <div className="border-b ml-2 mr-2">
                        <div className="flex justify-between w-full sm:w-[550px]">
                          <div className="text-slate-400">TOTAL</div>
                          <div className="flex">
                            {item?.scores?.split("/")[0]}{" "}
                            {item?.scores?.split("/")[1] &&
                              `(${item?.scores?.split("/")[1]} wkts, ${
                                item?.scores_full?.split("(")[1]?.split(" ")[0]
                              } Ov)`}
                          </div>
                        </div>
                      </div>

                      <div className="flex ml-2 w-full sm:w-[680px]">
                        <div
                          style={{ width: "150px" }}
                          className="text-slate-400 pr-14"
                        >
                          Didn’t Bat
                        </div>
                        <div className="text-[#0F19AF] ml-4">
                          <div className="flex flex-wrap gap-1">
                            {item?.did_not_bat?.map((player, playerIndex) => (
                              <span
                                onClick={() =>
                                  navigate(
                                    `/profiles/${
                                      player?.player_id
                                    }/${formatTitle(player?.name)}`
                                  )
                                }
                                className="hover:underline hover:cursor-pointer"
                                key={playerIndex}
                              >
                                {player?.name}
                                {playerIndex !== item.did_not_bat.length - 1 &&
                                  ","}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full sm:w-[680px] mt-2 bg-[white] rounded-lg shadow-lg">
                      <table className="p-2">
                        <thead className="border-b">
                          <tr className="bg-[#0F19AF] rounded-t-lg w-full sm:w-[680px] h-[45px] text-white">
                            <th className="w-[350px] pl-3 text-left">Bowler</th>
                            <th className="w-[50px] text-left">O</th>
                            <th className="w-[50px] text-left">M</th>
                            <th className="w-[50px] text-left">R</th>
                            <th className="w-[50px] text-left">W</th>
                            <th className="w-[50px] text-left">NB</th>
                            <th className="w-[50px] text-left">WD</th>
                            <th className="w-[50px] text-left">ECO</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.bowlers?.map((bowler, bowlerIndex) => (
                            <tr
                              key={bowlerIndex}
                              style={{
                                borderBottom: "1px solid #E5E7EB",
                              }}
                            >
                              <td
                                onClick={() =>
                                  navigate(
                                    `/profiles/${
                                      bowler?.bowler_id
                                    }/${formatTitle(bowler?.name)}`
                                  )
                                }
                                className="text-[#0F19AF] pl-3 hover:underline hover:cursor-pointer"
                              >
                                {bowler.name} {bowler?.role_str}
                              </td>
                              <td>{bowler.overs}</td>
                              <td>{bowler.maidens}</td>
                              <td>{bowler.run0}</td>
                              <td>{bowler.wickets}</td>
                              <td>{bowler.noballs}</td>
                              <td>{bowler.wides}</td>
                              <td>{bowler.econ}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="w-full sm:w-[680px] mt-2 bg-white rounded-lg shadow-lg">
                      <table className="w-full">
                        <thead className="border-b">
                          <tr className="bg-[#0F19AF] w-full h-[45px] text-white">
                            <th className="w-[350px] pl-3 text-left">
                              Powerplays
                            </th>
                            <th className="w-[150px] text-left">Over</th>
                            <th className="w-[150px] text-left">Runs</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="border-b">
                            <td></td>
                            <td>
                              {item?.powerplay?.p1?.startover} -{" "}
                              {item?.powerplay?.p1?.endover}
                            </td>
                            <td>{item?.result}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="w-full sm:w-[680px] mt-2 bg-white rounded-lg shadow-lg">
                      <table className="w-full">
                        <thead className="border-b">
                          <tr className="bg-[#0F19AF] w-full h-[45px] text-white">
                            <th className="w-[350px] pl-3 text-left">
                              Fall Of Wickets
                            </th>
                            <th className="w-[150px] text-left"></th>
                            <th className="w-[150px] text-left">Score</th>
                            <th className="w-[150px] text-left">Over</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.fows?.map((fow, fowIndex) => (
                            <tr key={fowIndex} className="border-b">
                              <td
                                style={{ color: "blue" }}
                                onClick={() =>
                                  navigate(
                                    `/profiles/${fow?.batsman_id}/${formatTitle(
                                      fow?.name
                                    )}`
                                  )
                                }
                                className="pl-3"
                              >
                                {fow?.name} {fow?.role_str}
                              </td>
                              <td className="pr-3">{fow?.how_out}</td>
                              <td className="pl-3">
                                {fow?.score_at_dismissal}
                              </td>
                              <td className="text-[#0F19AF] pt-2 pl-3">
                                {fow?.overs_at_dismissal}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {squadData?.innings?.length !== 0 && (
            <div className="w-[250px] full-width ">
              {banner1 && (
                <div className="bg-[#B3B3B3] text-white h-[550px]  flex justify-center items-center rounded-lg mt-2 auto-height">
                  <img
                    src={banner1?.image}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                    }}
                    alt=""
                  />
                </div>
              )}

              {banner2 && (
                <div className="bg-[#B3B3B3] text-white h-[550px]  flex justify-center items-center rounded-lg mt-2">
                  <img
                    src={banner2?.image}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                    }}
                    alt=""
                  />
                </div>
              )}
              {banner2 && (
                <div className="bg-[#B3B3B3] text-white h-[550px]  flex justify-center items-center rounded-lg mt-2">
                  <img
                    src={banner2?.image}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                    }}
                    alt=""
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div>
          <div className="mt-5 shadow-2xl w-[680px] ml-3 full-width zero-margin  small-padding hideClass">
            <div
              style={{ borderRadius: "10px 10px 0 0" }}
              className="bg-[#0F19AF] h-[45px] flex items-center text-white pl-2 small-text"
            >
              Match Info
            </div>

            <div className="flex justify-between border-b">
              <div
                className="text-slate-400 ml-2 xs-small-text"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Match
              </div>
              <div className="text-slate-400 mr-2 xs-small-text">
                {squadData?.short_title}
                {","} {squadData?.subtitle}
                {","} {squadData?.competition?.title}
              </div>
            </div>
            <div className="flex justify-between border-b">
              <div
                className="text-slate-400 ml-2 xs-small-text"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Series
              </div>
              <div className="text-slate-400 mr-2 xs-small-text">
                {squadData?.competition?.title}
              </div>
            </div>
            <div className="flex justify-between border-b">
              <div
                className="text-slate-400 ml-2 xs-small-text"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Date
              </div>
              <div className="text-slate-400 mr-2 xs-small-text">
                {formatDate11(squadData?.date_start)}
              </div>
            </div>
            <div className="flex justify-between border-b">
              <div
                className="text-slate-400 ml-2 xs-small-text"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Time
              </div>
              <div className="text-slate-400 mr-2 xs-small-text">
                {formatDateTime(squadData?.date_start)}
              </div>
            </div>
            <div className="flex justify-between border-b">
              <div
                className="text-slate-400 ml-2 xs-small-text"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Toss
              </div>
              <div className="text-slate-400 mr-2 xs-small-text">
                {squadData?.toss?.text}
              </div>
            </div>
            <div className="flex justify-between border-b">
              <div
                className="text-slate-400 ml-2 xs-small-text"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Venue
              </div>
              <div className="text-slate-400 mr-2 xs-small-text">
                {squadData?.venue?.name}
                {","} {squadData?.venue?.location}
              </div>
            </div>
            <div className="flex justify-between border-b">
              <div
                className="text-slate-400 ml-2 xs-small-text"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Umpires
              </div>
              <div className="text-slate-400 mr-2 xs-small-text">
                {squadData?.umpires?.split(",")[0]}
                {","}
                {squadData?.umpires?.split(",")[1]}
              </div>
            </div>
            <div className="flex justify-between border-b">
              <div
                className="text-slate-400 ml-2 xs-small-text"
                style={{ color: "black", fontWeight: "bold" }}
              >
                3rd Umpire
              </div>
              <div className="text-slate-400 mr-2 xs-small-text">
                {squadData?.umpires?.split(",")[2]}
              </div>
            </div>
            <div className="flex justify-between border-b">
              <div
                className="text-slate-400 ml-2 xs-small-text"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Referee
              </div>
              <div className="text-slate-400 mr-2 xs-small-text">
                {squadData?.referee}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scorecard;
